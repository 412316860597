import React from "react"
import PropTypes from "prop-types"

function Article({ title, content }) {
	return (
		<div className="about-grid">
			<h2>{title}</h2>
			<p>{content}</p>
		</div>
	)
}

Article.propTypes = {
	title: PropTypes.string,
	content: PropTypes.string,
}

Article.defaultProps = {
	title: "Once upon a time...",
	content: `The story this year just after the Lunar New Year in
	Vietnam, we decide to continue our journey to Cambodia, it was our dream to come back in the "country of
	smiles".
	We stopped at Phnom Penh, and Electric
	Kitchen was created, after get experience in differents
	restaurant accross the world (Paris, Amsterdam, London,
	Montreal, Perth, Sydney). Come and say hello, 308 Street nearby the famous Bassac
	Lane.`,
}
export default Article
